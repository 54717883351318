import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo
} from '../../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
            var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -10],keepaspectratio: true, axis:true, ticks:{visible:false}, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
			var resize = function () {
            brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
            brd1.fullUpdate();
            };
            window.onresize = resize;
			brd1.options.layer['image'] =1;
			brd1.options.layer['line'] =12;
      placeTitle(brd1, 'Cartesian Form of a Vector', '');
			//brd1.create('text', [-8.5, 9., '<b>  <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
			brd1.create('text', [9, 0.35, '<i>x&#770;</i>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
			brd1.create('text', [0.25, 9,  '<i>y&#770;</i>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
      placeLogo(brd1);
			var girl= brd1.create('image', ['/assets/pirate.svg', [-1., -0.15],[3, 3]], {opacity:1, fixed:true});
			var i=0;
			var PtO =brd1.create('point', [5,0], {name:'', size:0});
			var PtA =brd1.create('point', [0, 0],{name:'', face:'circle',size:0, strokeColor:'black', fillColor:'yellow', fixed:true, label:{CssStyle:'fontFamily:Oswald',offset:[5,-10], fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
			//var PtB =brd1.create('point', [function(){return boat.X()+0.5;}, function(){return boat.Y();}],{name:'B', face:'circle',size:0, strokeColor:'black', fillColor:'yellow', fixed:false, label:{CssStyle:'fontFamily:Oswald', offset:[5,-10],fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
			var PtB =brd1.create('point', [0.,0.],{name:'', face:'circle',size:1, strokeColor:'black', fillColor:'black', fixed:false, trace:false});
			//var a=brd1.create('angle', [PtO, PtA, PtB],{name:function(){return '&alpha; ='+ (JXG.Math.Geometry.angle(PtA, PtO, PtB)*180/Math.PI).toFixed(2)+'^o'},visible:true, radius:1,label:{CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
			var j=0;
			var boat = brd1.create('image', ['/assets/ship.svg', [function(){return PtB.X()-0.25;}, function(){return PtB.Y()-0.25;}],[0.5, 0.5]], {opacity:1,snapToGrid:false});
			var moveA= brd1.create('point', [0,0],{name:'', face:'circle',size:0, strokeColor:'black', fillColor:'yellow'});
			var moveB= brd1.create('point', [0,0],{name:'', face:'circle',size:0, strokeColor:'black', fillColor:'yellow'});
			var moveC= brd1.create('point', [0,0],{name:'', face:'circle',size:0, strokeColor:'black', fillColor:'yellow'});
			brd1.create('arrow', [moveC, moveA], {strokeColor:'blue', dash:1});
			brd1.create('arrow', [[0,0], moveB], {strokeColor:'blue', dash:1});
			brd1.create('arrow', [PtA, PtB], {visible:true, strokeColor:'red', strokeWidth:3});
			brd1.create('text', [function(){return PtB.X()*0.5;}, 0.65, function(){return ''+(PtB.X()).toFixed(2)}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}, visible:function(){if(j==0){return false}else{return true}}});
//
			//
			brd1.create('text', [function(){return PtB.X()*0.5-0.5*Math.sign(PtB.X());}, function(){return PtB.Y()*0.5+0.5;}, '<b>r </b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)},visible:function(){if(i==0){return false}else{return true}}});
			//
			brd1.create('text', [function(){return PtB.X()+0.25}, function(){return PtB.Y()*0.5;},function(){return ''+(PtB.Y()).toFixed(2)}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)},visible:function(){if(j==0){return false}else{return true}}});
//
			brd1.create('text', [-6.5,7.5, function(){return '<b>r</b>&#773; = ('+ (PtB.X()).toFixed(2)+') <i>x&#770;</i> + (' + (PtB.Y()).toFixed(2) +') <i>y&#770;</i>'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}, fixed:true,visible:function(){if(j==0){return false}else{return true}}});
//[]
			//brd1.create('image', ['/assets/piratee.svg', [-0.0, -1.3],[2, 2]], {opacity:1, fixed:true, visible:function(){if(PtB.X()<0){return true} else{return false}}});
//
      var a =0;
			var runb =function(){moveB.moveTo([PtB.X(), 0.0], 500)}
			var runc =function(){moveA.moveTo([PtB.X(), PtB.Y()], 500)}
      var runa =function(){a=Math.random(); PtB.moveTo([(4+4*a)*Math.cos(2*Math.PI*a), (4+4*a)*Math.sin(2*Math.PI*a)], 400)}
			//var runa =function(){PtB.moveTo([4*(1-2*Math.random()), 4*(1-2*Math.random())], 400)}
			boat.on('down', function(){j=1; moveC.moveTo([PtB.X(),0]); moveA.moveTo([PtB.X(), 0]); moveB.moveTo([0,0]);runb();runc();});
			girl.on('down',function(){i=1; j=0; PtB.moveTo([0.0, 0.0]);runa(); moveB.moveTo([0,0]); moveA.moveTo([PtB.X(),0]); moveC.moveTo([PtB.X(),0])});
    },
}
export default Boxes;
