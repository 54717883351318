<template>
  <div>
    <h3 ref="ia">
      Vectors and Scalars
    </h3>
    <p>
      To understand vectors, we first need to understand scalars.
      A scalar is an entity that requires only magnitude for its description. For example,  when you weigh an object, you need only one number (e.g., 5 kg, 10 kg, etc.) to describe the object's mass.  That is because mass is a scalar. The same is true for the length of an object, too. The length of an object requires only one number to describe.
      <br>
      Now, a vector is an entity that has both a magnitude and a direction. For example,  imagine kicking a ball in the game of soccer. To locate where the ball lands, we need to know both &mdash; how far you kicked and in which direction.  That's because the position of the object is a vector.
    </p>
    <br>
    <v-layout justify-center>
      <v-img contain
             src="/assets/ally.png"
             max-height="650px"
             max-width="650px"
      />
    </v-layout>
    <br>
    <h3 ref="rep">
      Expressing a Vector
    </h3>
    <p>
      Graphically, a vector is represented by an arrow pointing in the direction of the vector. The length of the arrow is equal to the magnitude of the vector. Mathematically, a vector can be expressed in two ways: (a) Through its magnitude and direction, and (b) through its components along the axes of a Cartesian system with origin at the tail of the vector.
    </p>
    <h3 ref="com">
      Component Form of a Vector
    </h3>
    <p>
      The most common way to express a vector is by its components along the axes of a Cartesian coordinate system with the origin located at the tail of the vector. To explain it further, let's get back to the example of the soccer player and soccer ball. Let's assume that the ball is initially located at a point with coordinates P=(0,0). Ally kicks the ball, and the ball lands at a point with coordinates Q=(a, b).  The ball's location can be described by a vector <b>r</b> that connects the ball's initial position P to its final position Q.
      Now, Ally decides to get the ball back. To reach the ball, Ally first walks a distance 'a' along the x-axis, and then distance 'b' along the y-axis.  Thus, Ally has decomposed the vector <b>r</b> into its components along the x and y axes. Therefore, one can write:
      $$\mathbf r = a \hat{x} + b \hat{y}$$
      Expressing a vector by its components is called the component form of the vector.
    </p>
    <h3 ref="pg">
      MagicGraph | Component Form of a Vector
    </h3>
    <p> Ally is our star soccer player, and she is ready to kick. Our goal is calculate the component form of the location vector <b>r</b> of the final location of the soccer ball. Follow the steps below: </p>
    <ul style="list-style-type: square;">
      <li><h5>Step 1</h5> Tap Ally on the shoulders to get started.  </li> <br>
      <li>
        <h5>Step 2</h5>
        Hover over the soccer ball to show its coordinates.
      </li> <br>
      <li> <h5>Step 3</h5>Tap on the soccer ball to calculate the x and y-components and to reveal the component form of its location vector. </li>
    </ul>
    <p> You can repeat the above steps any number of times to keep playing. </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Resistors',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Component Form of a Vector';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Vectors vs. Scalars', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Expressing a Vector', img:'/assets/number-2.svg', action: () => this.goto('rep')},
      {title: 'Component Form of a Vector', img:'/assets/number-3.svg', action: () => this.goto('com')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Cartesian Components of a Vector',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
